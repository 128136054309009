var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{ref:"submitsTable",staticClass:"text-nowrap",staticStyle:{"padding-bottom":"5rem"},attrs:{"hover":"","small":"","responsive":true,"fields":_vm.fields,"items":_vm.submits,"tbody-tr-class":"p-0"},scopedSlots:_vm._u([{key:"cell(index)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.index)+" ")]}},{key:"cell(evaluation.score)",fn:function(data){return [_c('div',{attrs:{"set":(_vm.onlyFeedback =
          !data.item.evaluation.score && data.item.evaluation.feedback)}},[_c('b-form-input',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
          _vm.onlyFeedback ? 'Komentár je nahraný, ale body neboli zadané!' : ''
        ),expression:"\n          onlyFeedback ? 'Komentár je nahraný, ale body neboli zadané!' : ''\n        ",modifiers:{"hover":true,"top":true}}],ref:("score-input-" + (data.index)),class:[
          { 'border-danger': _vm.onlyFeedback },
          data.item.scoreClass || '' ],attrs:{"lazy":"","size":"sm","placeholder":"-","autocomplete":"off","disabled":data.item.scoreFrozen},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }return _vm.selectScoreInput(data.index - 1)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }return _vm.selectScoreInput(data.index + 1)}],"change":function (newScore) { return _vm.changeScore(data.item, newScore); }},nativeOn:{"focus":function($event){return $event.target.select()}},model:{value:(data.item.evaluation.score),callback:function ($$v) {_vm.$set(data.item.evaluation, "score", $$v)},expression:"data.item.evaluation.score"}})],1)]}},{key:"cell(downloadSolution)",fn:function(data){return [_c('b-button',{ref:("submit-button-" + (data.item.id)),staticClass:"mx-1",class:['p-1', 'wide-button', data.item.solutionClass || ''],attrs:{"variant":"outline-dark","tabindex":"-1","disabled":!data.item.solution},on:{"click":function($event){return _vm.downloadSolution(data.item)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'envelope'],"size":"lg"}}),_vm._v(" "),_c('small',[_vm._v(" "+_vm._s(data.item.createdVerbose)+" ")])],1),(data.item.thumbnail)?_c('ThumbnailTooltip',{attrs:{"url":data.item.thumbnail,"target":function () { return _vm.$refs[("submit-button-" + (data.item.id))]; },"title":"Odovzdané riešenie"}}):_vm._e()]}},{key:"cell(downloadFeedback)",fn:function(data){return [_c('div',{attrs:{"set":(_vm.onlyScore =
          data.item.evaluation.score && !data.item.evaluation.feedback)}},[_c('b-button',{ref:("feedback-button-" + (data.item.id)),staticClass:"mx-1",class:[
          'p-1',
          'wide-button',
          data.item.feedbackClass || '',
          { 'btn-danger': _vm.onlyScore } ],attrs:{"variant":"outline-dark","tabindex":"-1","disabled":!data.item.evaluation.feedback},on:{"click":function($event){return _vm.downloadFeedback(data.item)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'comment'],"size":"lg"}}),_vm._v(" "),_c('small',[_vm._v(" "+_vm._s(data.item.evaluation.updatedVerbose || "-")+" ")])],1),(_vm.onlyScore)?_c('font-awesome-icon',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
          'Body boli zadané, ale komentár nie je nahraný!'
        ),expression:"\n          'Body boli zadané, ale komentár nie je nahraný!'\n        ",modifiers:{"hover":true,"top":true}}],staticClass:"exclamation-mark",attrs:{"icon":['fas', 'exclamation'],"size":"lg"}}):_vm._e(),(data.item.evaluation.thumbnail)?_c('ThumbnailTooltip',{attrs:{"url":data.item.evaluation.thumbnail,"cache-key":data.item.evaluation.updated,"target":function () { return _vm.$refs[("feedback-button-" + (data.item.id))]; },"title":"Opravené riešenie"}}):_vm._e()],1)]}},{key:"cell(done)",fn:function(data){return [_c('div',{staticClass:"d-flex h-100 position-relative pl-2 pt-1"},[(_vm.submitDone(data.item))?_c('font-awesome-icon',{staticClass:"text-success",attrs:{"icon":['fas', 'check'],"size":"lg"}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }