<!-- eslint-disable max-lines -->
<template>
  <div>
    <!-- Content has to be hidden manually with 'invisible' class,
    because the $refs is not reactive and drag&drop overlay wouldn't be shown.
    Therefore the Loading spinner doesn't have the slot populated -->
    <LoadingSpinner :is-loaded="!!roundEvaluators" />
    <div v-if="files.length">
      <b-list-group>
        <b-list-group-item
          v-for="file in files"
          :key="file.id"
          :variant="getVariant(file)"
        >
          {{ file.name }}
          <p v-if="file.error">
            <font-awesome-icon :icon="['fas', 'times']" />
            {{ file.error }}
          </p>
          <font-awesome-icon
            v-else-if="file.success"
            :icon="['fas', 'check']"
          />
          <font-awesome-icon v-else :icon="['fas', 'sync']" spin />
        </b-list-group-item>
      </b-list-group>
    </div>
    <div
      v-else
      class="my-5 text-center"
      :class="{ invisible: !roundEvaluators }"
    >
      <h4>Potiahni sem opravené riešenia</h4>
      <p>alebo</p>
      <vue-upload-component
        ref="uploader"
        v-model="files"
        name="feedback-file"
        :multiple="true"
        :drop="true"
        :drop-directory="true"
        @input="changeFeedback"
      />
      <b-button variant="outline-dark">
        <label for="feedback-file" class="m-0"> Vybrať súbory </label>
      </b-button>
    </div>
    <div
      v-show="$refs.uploader && $refs.uploader.dropActive"
      class="drop-active"
    >
      <h3>Potiahni sem opravené riešenia.</h3>
    </div>
  </div>
</template>

<script>
import {
  apiRounds,
  apiSubmits,
  constants,
  LoadingSpinner,
} from "frontend-common";
import VueUploadComponent from "vue-upload-component";

export default {
  name: "EvaluationDropzone",
  components: {
    VueUploadComponent,
    LoadingSpinner,
  },
  mixins: [apiSubmits, apiRounds],
  props: {
    submits: {
      type: Array,
      required: true,
    },
    convertSubmitData: {
      type: Function,
      required: true,
    },
    problem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      files: [],
      roundEvaluators: null,
      constants,
    };
  },
  computed: {},
  mounted() {
    this.setEvaluators();
  },
  methods: {
    idFromName(name) {
      const submitIdRegex = /-(\d+)[^-]*\./g;
      // Gets the subit id from a file name
      const matches = submitIdRegex.exec(name);
      if (!matches || matches.length !== 2) return null;
      return Number(matches[1]);
    },
    idBelongsToProblem(submitId) {
      // Checks whether given submitId is present in the current submits table.
      if (!submitId) return false;
      return this.submits.some((submit) => submit.id === submitId);
    },
    uploadEvaluationsWarning(files, submitIds, submitBelongs) {
      const h = this.$createElement;
      const list = [];
      for (const [i, file] of files.entries()) {
        if (submitBelongs[i]) continue;
        if (!submitIds[i]) {
          list.push(
            h("li", [
              `Súbor s názvom "${file.name}" nemá správny formát názvu.`,
            ]),
          );
          continue;
        }
        list.push(
          h("li", [
            `Súbor s názvom "${file.name}" patrí k Submitu č. ${submitIds[i]}, ` +
              "ktorý nepatrí k tejto úlohe.",
          ]),
        );
      }
      return h("div", [
        h("p", [
          "Niektoré zo súborov, ktoré si zvolil/-a, nemajú správny názov" +
            " alebo nepatria k tejto úlohe:",
        ]),
        h("ul", list),
        h("p", ["Súbory nebudú nahraté."]),
      ]);
    },
    evaluationNotRegistered(uploadInfos) {
      const h = this.$createElement;
      const fileNames = [];
      uploadInfos.forEach((info) => {
        if (info.registered) return;
        fileNames.push(
          h("li", [
            `Súbor "${info.filename}" patrí ku kategórií: `,
            h("strong", [info.category.name]),
          ]),
        );
      });
      const registerLink = h(
        "RouterLink",
        {
          props: {
            to: {
              name: "evaluators",
              params: { roundId: this.problem.round },
            },
          },
          nativeOn: {
            click: () => this.$bvModal.hide("evaluation-not-registered-modal"),
          },
          class: ["text-danger"],
        },
        ["Otvoriť prihlasovanie (riešenia sa nenahrajú)"],
      );
      return h("div", [
        h("p", [
          "Nahrávaš riešenia k úlohe, resp. kategórii na ktorú si sa neprihlásil/-a" +
            " ako opravovateľ/-ka. Si si istý/-á, že chceš pokračovať?",
        ]),
        h("ul", fileNames),
        registerLink,
      ]);
    },
    async changeFeedback(files) {
      if (files.length > 0 && files[0].type === this.constants.mimeTypes.ZIP) {
        await this.apiPostEvaluatedZipFile(this.problem.id, files[0].file);
        this.$emit("zipUploaded");
        this.files = [];
        return;
      }

      const submitIds = files.map((file) => this.idFromName(file.name));
      const submitBelongs = submitIds.map((submitId) =>
        this.idBelongsToProblem(submitId),
      );
      // bad files won't be uploaded unless 'force-upload' attribute is present in the url
      const forceUpload =
        new URL(window.location.href).searchParams.get("force-upload") !== null;

      // Check if the user is assigned for every file they are trying to upload
      const uploadInfos = [];
      submitIds.forEach((id, i) => {
        const submit = this.submits.find((s) => s.id === id);
        if (!submit) return;
        const registered = this.roundEvaluators.some(
          (evaluator) =>
            // Problem and User are being checked serverside see this.setEvaluators()
            evaluator.category === submit.enrollment.category.id,
        );
        uploadInfos.push({
          filename: files[i].name,
          category: submit.enrollment.category,
          registered,
        });
      });
      const evaluatesEveryUpload = uploadInfos.every((x) => x.registered);
      if (!evaluatesEveryUpload && !forceUpload) {
        const uploadAnyways = await this.$root.dangerModalConfirm(
          "Naozaj chceš nahrať súbory?",
          this.evaluationNotRegistered(uploadInfos),
          "lg",
          "evaluation-not-registered-modal",
        );
        if (!uploadAnyways) {
          this.files.splice(0, this.files.length);
          return;
        }
      }
      if (!submitBelongs.every((x) => x) && !forceUpload) {
        await this.$root.warningModalOk(
          "Súbory nie je možné nahrať",
          this.uploadEvaluationsWarning(files, submitIds, submitBelongs),
          "lg",
        );
        this.files.splice(0, this.files.length);
      }
      files.forEach((file, i) =>
        this.processSubmit(file, submitIds[i], submitBelongs[i]),
      );
    },
    processSubmit(file, submitId, belongs) {
      const removalFunction = () => {
        // Removes this file from the dropzone
        const index = this.files.indexOf(file);
        this.files.splice(index, 1);
      };
      if (!submitId) {
        file.error = "Nesprávny názov súboru!";
        setTimeout(removalFunction, 5000);
        return;
      }
      this.apiPatchEvaluation(submitId, { feedback: file.file })
        .then((response) => {
          // We do not update the submit in the table unless it belongs to this problem
          if (belongs) {
            // Updates the submit information
            const submitIndex = this.submits.findIndex(
              (submit) => submit.id === response.id,
            );
            const newSubmitData = this.convertSubmitData(response, submitIndex);
            newSubmitData.feedbackClass = "long-border-success";
            setTimeout(() => (newSubmitData.feedbackClass = ""), 7000);
            this.submits.splice(submitIndex, 1, newSubmitData);
          }
          file.success = true;
          setTimeout(removalFunction, 2500);
        })
        .catch(() => {
          file.error = "Chyba pri nahrávaní.";
          setTimeout(removalFunction, 5000);
        });
    },
    async setEvaluators() {
      const { round: roundId, id: problemId } = this.problem;
      this.roundEvaluators = await this.apiRoundEvaluators(
        roundId,
        problemId,
        this.$root.state.user.id,
      );
    },
    getVariant(file) {
      if (file.error) return "danger";
      if (file.success) return "success";
      return "warning";
    },
  },
};
</script>

<style scoped>
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}

.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 3em;
  color: #fff;
  padding: 0;
}
</style>
