<template>
  <b-container fluid class="my-3">
    <b-row no-gutters>
      <b-col lg="12" xl="10">
        <b-container fluid class="mt-3">
          <b-button
            variant="outline-info"
            :to="{ name: 'evaluation-overview' }"
          >
            <font-awesome-icon :icon="['fas', 'arrow-left']" /> Späť na prehľad
          </b-button>
          <b-row class="my-3">
            <b-col>
              <h1>{{ problemName }}</h1>
              <p>
                Riešenia si môžeš stiahnuť všetky v zip archíve, alebo po
                jednom. Keď pridáš svoj komentár do PDF, tak ho potiahni do
                tohto okna a nahrá sa ku správnemu riešiteľovi. Pozor, PDF-ku
                nesmieš zmeniť názov, inak ho nebudeme vedieť napárovať.
              </p>
              <p>
                Body zadávaj do na to určených okienok. Body sa ukladajú
                akonáhle okienko opustíš (uvidíš zelený rámik), alebo stlačíš
                <i>Enter</i>. Medzi okienkami sa môžeš pohybovať aj šípkou hore
                a dole.
              </p>
            </b-col>
            <b-col md="auto">
              <b-button
                variant="primary"
                :disabled="archiveLoading || !isLoaded"
                @click.prevent="downloadSubmitsArchive"
              >
                <LoadingSpinner :is-loaded="!archiveLoading && isLoaded" small>
                  <font-awesome-icon :icon="['fas', 'download']" />
                  Stiahnuť všetky riešenia
                </LoadingSpinner>
              </b-button>
            </b-col>
          </b-row>
          <LoadingSpinner :is-loaded="isLoaded">
            <EvaluationSubmitsTable
              :submits="submits"
              :score-validator="isScoreValid"
            />
          </LoadingSpinner>
        </b-container>
      </b-col>
      <b-col lg="12" xl="2">
        <LoadingSpinner :is-loaded="!!problem">
          <EvaluationDropzone
            :problem="problem"
            :submits="submits"
            :convert-submit-data="convertSubmitData"
            @zipUploaded="refreshAll"
          />
        </LoadingSpinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  apiProblems,
  apiSubmits,
  LoadingSpinner,
  utils,
} from "frontend-common";
import EvaluationDropzone from "@/components/EvaluationDropzone.vue";
import EvaluationSubmitsTable from "@/components/EvaluationSubmitsTable.vue";

export default {
  name: "Evaluation",
  components: {
    EvaluationDropzone,
    EvaluationSubmitsTable,
    LoadingSpinner,
  },
  mixins: [apiProblems, apiSubmits],
  props: {
    problemId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      archiveLoading: false,
      isLoaded: false,
      submits: [],
      files: [],
      problem: null,
    };
  },
  computed: {
    problemName() {
      if (!this.problem) return "";
      return `${this.problem.number}. ${this.problem.name}`;
    },
  },
  watch: {},
  mounted() {
    this.refreshAll();
  },
  methods: {
    isScoreValid(score) {
      return !score || (0 <= score && score <= 5);
    },
    convertSubmitData(submit, index) {
      submit.index = index + 1;
      submit.evaluation ||
        (submit.evaluation = {
          feedback: null,
          updated: null,
          updatedVerbose: null,
          score: null,
        });

      submit.createdVerbose = utils.dateFromNow(submit.created);
      if (submit.evaluation.updated)
        submit.evaluation.updatedVerbose = utils.dateFromNow(
          submit.evaluation.updated,
        );
      submit.scoreClass = this.isScoreValid(submit.evaluation.score)
        ? ""
        : "border-warning";
      submit.scoreFrozen = false;
      submit.solutionClass = "";
      submit.feedbackClass = "";

      return submit;
    },
    downloadSubmitsArchive() {
      this.archiveLoading = true;
      this.apiDownloadSubmitsArchive(this.problemId).finally(() => {
        // This is to prevent spamming this expensive request
        setTimeout(() => (this.archiveLoading = false), 5000);
      });
    },
    refreshAll() {
      this.apiSubmits(this.problemId)
        .then(
          (response) => (this.submits = response.map(this.convertSubmitData)),
        )
        .finally(() => (this.isLoaded = true));

      this.apiProblem(this.problemId).then((response) => {
        this.problem = response;
        if (this.problem.interactive) {
          this.$router.push({ name: "evaluation-overview" });
          this.$root.dangerToast("Interaktívna úloha sa nedá opravovať.");
        }
      });
    },
  },
};
</script>
