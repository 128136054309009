<!-- eslint-disable vue/no-deprecated-v-on-native-modifier -->
<!-- eslint-disable max-lines -->
<template>
  <b-table
    ref="submitsTable"
    hover
    small
    :responsive="true"
    class="text-nowrap"
    :fields="fields"
    :items="submits"
    style="padding-bottom: 5rem"
    tbody-tr-class="p-0"
  >
    <template v-slot:cell(index)="data">
      {{ data.item.index }}
    </template>

    <!-- eslint-disable-next-line vue/valid-v-slot -->
    <template v-slot:cell(evaluation.score)="data">
      <div
        :set="
          (onlyFeedback =
            !data.item.evaluation.score && data.item.evaluation.feedback)
        "
      >
        <b-form-input
          :ref="`score-input-${data.index}`"
          v-model="data.item.evaluation.score"
          v-b-popover.hover.top="
            onlyFeedback ? 'Komentár je nahraný, ale body neboli zadané!' : ''
          "
          lazy
          size="sm"
          placeholder="-"
          autocomplete="off"
          :class="[
            { 'border-danger': onlyFeedback },
            data.item.scoreClass || '',
          ]"
          :disabled="data.item.scoreFrozen"
          @keydown.up="selectScoreInput(data.index - 1)"
          @keydown.down="selectScoreInput(data.index + 1)"
          @focus.native="$event.target.select()"
          @change="(newScore) => changeScore(data.item, newScore)"
        />
      </div>
    </template>

    <template v-slot:cell(downloadSolution)="data">
      <b-button
        :ref="`submit-button-${data.item.id}`"
        variant="outline-dark"
        :class="['p-1', 'wide-button', data.item.solutionClass || '']"
        class="mx-1"
        tabindex="-1"
        :disabled="!data.item.solution"
        @click="downloadSolution(data.item)"
      >
        <font-awesome-icon :icon="['fas', 'envelope']" size="lg" />&nbsp;
        <small>
          {{ data.item.createdVerbose }}
        </small>
      </b-button>

      <!-- tooltip with thumbnail -->
      <ThumbnailTooltip
        v-if="data.item.thumbnail"
        :url="data.item.thumbnail"
        :target="() => $refs[`submit-button-${data.item.id}`]"
        title="Odovzdané riešenie"
      />
    </template>

    <template v-slot:cell(downloadFeedback)="data">
      <div
        :set="
          (onlyScore =
            data.item.evaluation.score && !data.item.evaluation.feedback)
        "
      >
        <b-button
          :ref="`feedback-button-${data.item.id}`"
          variant="outline-dark"
          :class="[
            'p-1',
            'wide-button',
            data.item.feedbackClass || '',
            { 'btn-danger': onlyScore },
          ]"
          tabindex="-1"
          :disabled="!data.item.evaluation.feedback"
          class="mx-1"
          @click="downloadFeedback(data.item)"
        >
          <font-awesome-icon :icon="['fas', 'comment']" size="lg" />&nbsp;
          <small>
            {{ data.item.evaluation.updatedVerbose || "-" }}
          </small>
        </b-button>

        <font-awesome-icon
          v-if="onlyScore"
          v-b-popover.hover.top="
            'Body boli zadané, ale komentár nie je nahraný!'
          "
          :icon="['fas', 'exclamation']"
          size="lg"
          class="exclamation-mark"
        />

        <!-- tooltip with thumbnail -->
        <ThumbnailTooltip
          v-if="data.item.evaluation.thumbnail"
          :url="data.item.evaluation.thumbnail"
          :cache-key="data.item.evaluation.updated"
          :target="() => $refs[`feedback-button-${data.item.id}`]"
          title="Opravené riešenie"
        />
      </div>
    </template>

    <template v-slot:cell(done)="data">
      <div class="d-flex h-100 position-relative pl-2 pt-1">
        <font-awesome-icon
          v-if="submitDone(data.item)"
          :icon="['fas', 'check']"
          size="lg"
          class="text-success"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import { apiSubmits, ThumbnailTooltip } from "frontend-common";
import constants from "@/constants";

export default {
  name: "EvaluationSubmitsTable",
  components: {
    ThumbnailTooltip,
  },
  mixins: [apiSubmits],
  props: {
    submits: {
      type: Array,
      required: true,
    },
    scoreValidator: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        { key: "index", label: "#", sortable: true },
        {
          key: "enrollment.user.full_name",
          label: "Meno",
          sortable: true,
          tdClass: "column-name",
        },
        {
          key: "enrollment.school",
          label: "Škola",
          sortable: true,
          tdClass: "column-name",
        },
        { key: "enrollment.category.name", label: "K", sortable: true },
        { key: "evaluation.score", label: "Body", tdClass: "column-score" },
        {
          key: "downloadSolution",
          label: "Riešenie",
          tdClass: "column-download-button",
          thClass: "column-download-button",
        },
        {
          key: "downloadFeedback",
          label: "Komentár",
          tdClass: "column-download-button",
          thClass: "column-download-button",
        },
        { key: "done", label: "Hotovo" },
      ],
    };
  },
  computed: {},
  methods: {
    downloadSolution(submit) {
      this.$root
        .downloadFile(
          {
            url: submit.solution,
            cache: { maxAge: constants.maxAgeDefaults.mid },
          },
          `${submit.unique_file_name}${submit.solution_format}`,
          constants.mimeTypes.PDF, // TODO support for different mime types
        )
        .then(() => (submit.solutionClass = "border-success"))
        .catch(() => (submit.solutionClass = "border-fail"))
        .finally(() => setTimeout(() => (submit.solutionClass = ""), 500));
    },
    downloadFeedback(submit) {
      this.$root
        .downloadFile(
          {
            url: submit.evaluation.feedback,
            cache: { maxAge: constants.maxAgeDefaults.short },
          },
          `${submit.unique_file_name}${submit.evaluation.feedback_format}`,
          constants.mimeTypes.PDF, // TODO support for different mime types
        )
        .then(() => (submit.feedbackClass = "border-success"))
        .catch(() => (submit.feedbackClass = "border-fail"))
        .finally(() => setTimeout(() => (submit.feedbackClass = ""), 500));
    },
    selectScoreInput(index) {
      if (index < 0 || index >= this.submits.length) return;
      this.$refs[`score-input-${index}`].focus();
      // TODO $nextTick does not work here for some reason
      setTimeout(() => this.$refs[`score-input-${index}`].select(), 0);
    },
    changeScore(submit, newScore) {
      // We use this callback instead of watching for data changes
      // because they are in an array, which is hard to solve using $watch.
      newScore = newScore.replace(/,/g, ".");
      submit.evaluation.score = newScore; // Updates the value currently shown in table ',' -> '.'
      submit.scoreClass = "";
      submit.scoreFrozen = true;
      this.apiPatchEvaluation(submit.id, { score: newScore })
        .then(() => {
          const validScore = this.scoreValidator(newScore);
          submit.scoreClass = validScore ? "border-success" : "border-warning";
          setTimeout(() => {
            if (validScore) submit.scoreClass = "";
            submit.scoreFrozen = false;
          }, 1000);
        })
        .catch(() => {
          submit.scoreClass = "border-fail";
          setTimeout(() => (submit.scoreFrozen = false), 1000);
        });
    },
    submitDone(submit) {
      // Make sure the score is set
      return (
        submit.evaluation.score &&
        // Make sure that there is no indication of failure
        (submit.scoreClass === "border-success" || submit.scoreClass === "") &&
        // Make sure the feedback was submitted
        submit.evaluation.feedback
      );
    },
  },
};
</script>

<style scoped>
.column-name {
  white-space: pre-wrap;
}

@media only screen and (min-width: 768px) {
  .column-name {
    word-break: break-all;
  }
}

.column-score {
  min-width: 100px;
  max-width: 100px;
}

.column-download-button {
  max-width: 170px;
  width: 170px;
}

.wide-button {
  min-width: 160px;
}

@keyframes border-success {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: var(--success);
  }
  100% {
    border-color: transparent;
  }
}

@keyframes long-border-success {
  10%,
  90% {
    background-color: var(--success);
  }
}

@keyframes border-fail {
  0% {
    border-color: transparent;
  }
  50%,
  100% {
    border-color: var(--danger);
  }
}

.border-success {
  animation-name: border-success;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  border-width: 3px !important;
}

.border-warning {
  border-color: var(--warning);
  border-width: 3px !important;
}

.border-danger {
  border-color: var(--danger);
  border-width: 3px !important;
}

.long-border-success {
  animation-name: long-border-success;
  animation-duration: 7s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  border-width: 3px !important;
}

.border-fail {
  animation-name: border-fail;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  border-width: 3px !important;
}

.exclamation-mark {
  color: var(--danger);
  transform: translateX(-0.4em) rotate(30deg) scale(1.2);
}
</style>
